<template>
  <v-layout max-width class=" max-height flex-column flex-justify-between">
    <div class="flex-0 flex-shrink-0 flex-y-center flex-justify-between px-2 white mb-2" style>
      <!-- @change="active=[trees[0].id]"  -->
      <!-- <v-tabs v-model="tab" @change="tab==3?taskTemplateManhourGet():getList()"> -->
      <v-tabs v-model="tab" @change="tabChange()">
        <v-tab :href="`#1`">项目</v-tab>
        <v-tab :href="`#2`">日常任务</v-tab>
        <v-tab :href="`#3`">配置</v-tab>
        <v-tab :href="`#4`">阶段</v-tab>
      </v-tabs>
    </div>
    <div v-if="['1','2'].includes(tab)" class="flex-1 flex-justify-start flex-justify-between overflow-hidden">
      <div class="flex-0 flex-column overflow-hidden white" style="width:300px; border-right:1px solid #e8e8e8 !important;">
        <div class=" flex-0 flex-y-center mt-3 px-1 mb-3">
          <v-btn
            color="#00bea9"
            depressed
            dark
            small
            height="30"
            style="padding:0 8px"
            min-width="30"
            class="ml-2"
            @click="dialog.parentName='',dialog.activeParent=[],dialog.roleIds=[],dialog.data={},dialog.show=true"
          >
            <v-icon size="16">mdi-plus</v-icon>添加任务
          </v-btn>
          <!-- <template v-if="active&&active.length">
            <v-btn
              color="error"
              depressed
              dark
              small
              @click="activeList($api.folderTemplateDelete,'delete','删除',{id:active[0]})"
              style="height:32px;padding:0 8px;"
              min-width="30"
              class="ml-2"
            >
              <v-icon size="18">mdi-delete-outline</v-icon>删除文件夹
            </v-btn>
            </template> -->
        </div>
        <div class=" flex-1 px-3 overflow-auto">
          <!-- :active.sync="active"
              @update:active="chooseTree"
              activatable -->
          <v-treeview
            class=""
            item-children="children"
            :items="trees"
            :open.sync="open"
            item-text="taskName"
            transition
          >
            <template v-slot:label="{ item }">
              <div
                class="flex-y-center"
                style="min-height: 38px"
                @click="updateActive(item)"
              >
                <div
                  class="max-width max-height flex-y-center user-select"
                  style="min-height: 38px"
                  :title="item.taskName"
                >
                  <a
                    class="grey--text text--darken-3 pl-1 max-width max-height flex-y-center"
                    :class="(treeActive.length&&(treeActive[0]===item.id))?'light-blue lighten-5':''"
                    style="min-height: 38px"
                  >{{ item.taskName }}
                  </a>
                </div>
              </div>
            </template>
          </v-treeview>
          <!-- selection-type="independent"
              selectable
              v-model="selection" -->
        </div>
      </div>
      <div class="flex-1 flex-column flex-justify-between white">
        <!-- border-bottom -->
        <v-tabs v-model="rightTab" class=" flex-0" style="border-bottom:1px solid #e8e8e8 !important">
          <v-tab :href="`#1`">任务信息</v-tab>
        </v-tabs>
        <form v-if="treeActive&&treeActive.length" class=" flex-1 pt-12" @submit.prevent="submit('edit')">
          <div class="mb-8 flex-align-start">
            <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
              <span class="red--text ml-1">*</span>任务名称：
            </div>
            <form-item
              v-model="information.data.taskName"
              style="width:300px;"
              placeholder="任务名称"
              required
              type="text"
            />
          </div>
          <div class="mb-8 flex-align-start">
            <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
              上级任务：
            </div>
            <v-menu
              v-model="information.showMenu"
              :close-on-content-click="false"
              bottom
              max-height="300"
              offset-overflow
              transition="slide-y-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <div style="width:300px !important;height:36px;background:#fdfdfd;" class="default-input flex-justify-between" color="primary" v-on="on">
                  <input
                    v-model="information.parentName"
                    placeholder="上级任务"
                    readonly
                    class="max-width"
                    type="text"
                    style="background:#fdfdfd;"
                  >
                  <v-icon v-if="information.data.parentId" size="16" @click.stop="information.data.parentId='',information.parentName='',information.activeParent=[],$forceUpdate()">mdi-close</v-icon>
                </div>
              </template>
              <v-card class=" elevation-0">
                <!-- dialog.data.parentId=(dialog.activeParent.length?dialog.activeParent[0]:'') -->
                <v-treeview
                  class=""
                  :active.sync="information.activeParent"
                  item-children="children"
                  activatable
                  :items="trees"
                  item-text="taskName"
                  transition
                  return-object
                  @update:active="chooseParent"
                />
              </v-card>
            </v-menu>
          </div>
          <div class="mb-8 flex-align-start">
            <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
              <span class="red--text ml-1">*</span>是否允许填写工时：
            </div>
            <form-item
              v-model="information.data.manhourEnable"
              style="width:300px;"
              :options="[{text:'是',value:'1'},{text:'否',value:'0'}]"
              required
              type="radio"
            />
          </div>
          <div class="mb-8 flex-align-start">
            <div class="flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
              <span class="red--text ml-1">*</span>适用组织角色：
            </div>
            <form-item
              v-model="information.roleIds"
              style="width:300px;"
              :options="roleList"
              placeholder="组织角色"
              multiple
              dense
              type="select"
              :item-text="`roleName`"
              :item-key="`id`"
            />
          </div>
          <div class="mb-12 flex-align-start">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
              <span class="red--text ml-1">*</span>排序：
            </div>
            <form-item
              v-model="information.data.sort"
              style="width:300px;"
              placeholder="排序"
              required
              type="number"
            />
          </div>
          <div class="mb-10 flex-align-start">
            <div
              class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"
              style="width:170px;"
            />
            <v-btn type="submit" color="primary" height="30" :loading="btnLoading" depressed dark width="100" class="mr-2">保存</v-btn>
            <!-- taskTemplateManhourGet -->
            <v-btn v-if="information.data.enableFlag==='1'" color="error" depressed dark width="100" height="30" class="mr-2" @click="activeList($api.taskTemplateDisable,'put','禁用',{taskId:information.data.id})">禁用</v-btn>
            <v-btn v-else color="primary" depressed dark width="100" height="30" class="mr-2" @click="activeList($api.taskTemplateEnable,'put','恢复',{taskId:information.data.id})">恢复</v-btn>
            <v-btn color="error" depressed dark width="100" height="30" @click="activeList($api.taskTemplateDelete,'delete','删除',{id:information.data.id})">删除</v-btn>
          </div>
        </form>
      </div>
      <!-- </div> -->
    </div>
    <div v-if="['3'].includes(tab)" class="flex-1 white pt-12">
      <div class="mb-6 flex-align-start">
        <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
          <span class="red--text ml-1">*</span>每天标准工时数：</div>
        <form-item
          v-model="manhourValue"
          style="width:300px;"
          class="mr-0"
          placeholder="每天标准工时数"
          required
          type="text"
        />
      </div>
      <div class="mb-6 flex-align-start">
        <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" />
        <v-btn color="primary" depressed dark width="100" height="30" class="mr-2" :loading="btnLoading" @click="taskTemplateManhourEdit">保存</v-btn>
      </div>
    </div>
    <div v-if="['4'].includes(tab)" class="flex-1 white" style="width:70%">
      <v-btn
        color="primary"
        depressed
        small
        height="30"
        style="padding:0 8px"
        min-width="30"
        class="mr-2 mb-2"
        @click="stageDialog.show = true, stageDialog.data.stageName = '',stageDialog.data.sort = '',stageDialog.id = ''"
      >
        <v-icon size="18">mdi-plus</v-icon>新增
      </v-btn>
      <v-data-table
        :headers="stageHeaders"
        :items="stageList"
        :items-per-page="stageSearch.pageSize"
        class="pb-5 max-width header-grey"
        hide-default-footer
        item-key="id"
        no-data-text="暂无查询数据"
        :loading="stageListLoading"
        disable-sort
      >
        <template v-slot:item.statusFlag="{item}">
          <div v-if="item.statusFlag == '1'" style="color: red">禁用</div>
          <div v-else>正常</div>
        </template>
        <template v-slot:item.action="{item}">
          <div class=" flex-y-center">
            <v-icon color="primary" size="18" title="编辑" class=" mr-3" @click="stageDialog.show = true,btnLoading = false, stageDialog.id = item.id,stageDialog.data.sort = item.sort, stageDialog.data.stageName = item.stageName ">mdi-square-edit-outline</v-icon>
            <v-icon :color="item.statusFlag == '1'? 'primary' : 'error'" size="20" :title="item.statusFlag == '1'? '恢复' : '禁用'" class=" mr-3" @click="activeListStatus($api.taskStageUpdateStatus,'put',item,{id:item.id, statusFlag:item.statusFlag == '1'? '0' : '1'})">mdi-cancel</v-icon>
            <v-icon color="error" size="20" title="删除" class=" mr-3" @click="activeListDelete($api.taskStageDelete,'delete','删除',{id:item.id})">mdi-delete-outline</v-icon>
          </div>
        </template>
      </v-data-table>
      <div class="flex-justify-start">
        <pagination
          v-model="stageSearch.pageNo"
          :size="stageSearch.pageSize"
          :total="stageTotal"
          @input="getStageList"
          @changePageSize="stageChangePageSize"
        />
      </div>
    </div>

    <!-- 新增编辑阶段 -->
    <v-dialog v-model="stageDialog.show" width="600">
      <v-card>
        <form @submit.prevent="stageSubmit()">
          <v-card-title class="font-weight-bold">{{ stageDialog.id? '编辑' : '新增' }}</v-card-title>
          <v-divider />
          <v-card-text class="py-5 body-1">
            <div class="flex-1 flex-column overflow-auto mt-7 px-12">
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>阶段名称：</div>
                <form-item
                  v-model="stageDialog.data.stageName"
                  class="mr-0"
                  placeholder="任务名称"
                  required
                  type="text"
                />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>序号：
                </div>
                <form-item
                  v-model="stageDialog.data.sort"
                  class="mr-0"
                  placeholder="序号"
                  required
                  type="number"
                />
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <div class="max-width flex-y-center flex-justify-between">
              <div class="flex-1 flex-justify-end">
                <v-btn width="80" :loading="btnLoading" height="30" class="mr-2" depressed @click="stageDialog.show = false,stageDialog.data={}">取消</v-btn>
                <v-btn width="80" type="submit" height="30" :loading="btnLoading" class color="primary" depressed>确定</v-btn>
              </div>
            </div>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
    <!-- 阶段end -->

    <!-- 编辑 添加任务start -->
    <v-dialog v-model="dialog.show" width="600">
      <v-card>
        <form @submit.prevent="submit()">
          <v-card-title class="font-weight-bold">添加任务</v-card-title>
          <v-divider />
          <v-card-text class="py-5 body-1">
            <div class="flex-1 flex-column overflow-auto mt-7 px-12">
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>任务名称：</div>
                <form-item
                  v-model="dialog.data.taskName"
                  class="mr-0"
                  placeholder="任务名称"
                  required
                  type="text"
                />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  上级任务：
                </div>
                <v-menu
                  v-model="dialog.showMenu"
                  :close-on-content-click="false"
                  bottom
                  max-height="300"
                  offset-overflow
                  transition="slide-y-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <div class="  flex-justify-between default-input" color="primary" v-on="on">
                      <input
                        v-model="dialog.parentName"
                        placeholder="上级任务"
                        readonly
                        class=" flex-1 max-width"
                        type="text"
                      >
                      <v-icon v-if="dialog.data.parentId" size="16" @click.stop="dialog.data.parentId='',dialog.parentName='',dialog.activeParent=[],$forceUpdate()">mdi-close</v-icon>

                    </div>
                  </template>
                  <v-card class=" elevation-0">
                    <!-- dialog.data.parentId=(dialog.activeParent.length?dialog.activeParent[0]:'') -->
                    <v-treeview
                      class=""
                      :active.sync="dialog.activeParent"
                      item-children="children"
                      activatable
                      :items="trees"
                      item-text="taskName"
                      transition
                      return-object
                      @update:active="chooseParent"
                    />
                  </v-card>
                </v-menu>
              </div>

              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>是否允许填写工时：
                </div>
                <form-item
                  v-model="dialog.data.manhourEnable"
                  :options="[{text:'是',value:'1'},{text:'否',value:'0'}]"
                  required
                  type="radio"
                />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>适用组织角色：
                </div>
                <form-item
                  v-model="dialog.roleIds"
                  class=" flex-1"
                  :options="roleList"
                  placeholder="组织角色"
                  multiple
                  dense
                  type="select"
                  :item-text="`roleName`"
                  :item-key="`id`"
                />
              </div>

              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>排序：
                </div>
                <form-item
                  v-model="dialog.data.sort"
                  class="mr-0"
                  placeholder="排序"
                  required
                  type="number"
                />
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <div class="max-width flex-y-center flex-justify-between">
              <div class="flex-1 flex-justify-end">
                <v-btn width="80" :loading="btnLoading" height="30" class="mr-2" depressed @click="dialog.show = false,dialog.data={}">取消</v-btn>
                <v-btn width="80" type="submit" height="30" :loading="btnLoading" class color="primary" depressed>确定</v-btn>
              </div>
            </div>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
    <!-- 编辑 添加任务 end -->

  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      // appliedToSubfolders:'',
      permissions: [],
      permissionsObj: {},
      tab: '1',
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {}
      },
      total: 0, // 权限总条数
      active: [], // 选中的树的节点id
      treeActive: [],
      trees: [], // 树
      open: [], // 打开的节点
      rightTab: null, // 右侧选中的tab
      information: {
        data: {},
        showMenu: false,
        activeParent: [],
        parentName: '',
        roleIds: []
      }, // 选中的树的节点信息
      listLoading: false,
      selected: [],
      dialog: { // 添加弹窗
        show: false,
        type: '',
        showMenu: false,
        activeParent: [],
        roleIds: [],
        data: {}
      },
      selection: [],
      btnLoading: false,
      permissionDialog: false,
      roleList: [], // 组织角色数组列表
      manhourValue: '',

      stageSearch: {
        pageSize: 10,
        pageNo: 1,
        data: {}
      },
      stageTotal: 0, // 权限总条数
      stageHeaders: [
        { text: '阶段名称', value: 'stageName' },
        { text: '排序', value: 'sort' },
        { text: '状态', value: 'statusFlag' },
        { text: '操作', value: 'action' }
      ],
      stageList: [],
      stageListLoading: false,
      stageDialog: { // 添加弹窗
        show: false,
        data: {},
        id: ''
      }
    }
  },
  mounted() {
    this.getList()
    this.getRoleList()
  },
  methods: {
    // 列表项禁用
    activeListStatus(url, method, item, params) {
      const title = Number(item.statusFlag) ? '恢复' : '禁用'
      this.$confirm({ text: '是否确认' + title + '当前阶段？' })
        .then(vm => {
          const param = new URLSearchParams()
          for (const i in params) {
            param.append(i, params[i] || '')
          }
          vm.loading = true
          this.$http[method](url, { data: param })
            .then(res => {
              vm.show = false
              vm.loading = false
              this.$message.success(res.message)
              this.getStageList()
            })
            .catch(() => {
              vm.show = false
              vm.loading = false
            })
        })
        .catch(() => {})
    },
    // 列表项删除
    activeListDelete(url, method, title, params) {
      this.$confirm({ text: '是否确认' + title + '当前阶段？' })
        .then(vm => {
          const param = new URLSearchParams()
          for (const i in params) {
            param.append(i, params[i] || '')
          }
          vm.loading = true
          this.$http[method](url, { data: param })
            .then(res => {
              vm.show = false
              vm.loading = false
              this.$message.success(res.message)
              this.getStageList()
            })
            .catch(() => {
              vm.show = false
              vm.loading = false
            })
        })
        .catch(() => {})
    },
    tabChange() {
      if (Number(this.tab) === 1 || Number(this.tab) === 2) {
        this.getList()
      }
      if (Number(this.tab) === 3) {
        this.taskTemplateManhourGet()
      }
      if (Number(this.tab) === 4) {
        this.getStageList()
      }
    },
    // 分页
    stageChangePageSize(e) {
      this.stageSearch.pageSize = e
      this.stageSearch.pageNo = 1
      this.$forceUpdate()
      this.getStageList()
    },
    getStageList() {
      this.stageListLoading = true
      const param = new URLSearchParams()
      param.append('pageNo', this.stageSearch.pageNo)
      param.append('pageSize', this.stageSearch.pageSize)
      this.$http
        .get(this.$api.taskStageListPage, { data: param })
        .then(res => {
          this.stageList = (res.result && res.result.records) || []
          this.stageTotal = (res.result && res.result.total) || 0
          this.stageListLoading = false
        }).catch(() => {
          this.stageListLoading = false
        })
    },
    // 确认新增阶段
    stageSubmit() {
      let url = this.$api.taskStageAdd
      let method = 'post'
      let _data = {}
      if (this.stageDialog.id) {
        // 编辑
        url = this.$api.taskStageEdit
        method = 'post'
        _data = { ...this.stageDialog.data, id: this.stageDialog.id }
      } else {
        _data = { ...this.stageDialog.data }
      }
      this.btnLoading = true
      this.$http[method](url, { data: { ..._data }})
        .then(res => {
          this.$message.success(res.message)
          this.stageDialog.show = false
          this.stageDialog.data = {}
          this.btnLoading = false
          this.getStageList()
        })
        .catch(() => {
          this.getStageList()
          this.btnLoading = false
        })
    },

    // 文件夹-获取目录树
    getList(type) {
      const param = new URLSearchParams()
      param.append('taskCategory', this.tab)
      this.$http.get(this.$api.taskTemplateTree, { data: param }).then(res => {
        this.trees = res.result || []
        if (type === 'edit') {
          // this.chooseTree(this.active)
        } else {
          if (this.trees && this.trees.length) {
            this.treeActive = [this.trees[0].id]
            // this.chooseTree(this.treeActive)
            this.updateActive(this.trees[0])
          } else {
            this.active = []
            this.treeActive = []
            this.information.parentName = ''
            this.information.roleIds = []
            this.information.data = {} // 选中节点的内容
            this.old_information = {}
            // this.appliedToSubfolders=''
            this.permissionsObj = {}
            this.rightTab = '1'
            this.search.pageNo = 1
          }
        }
      })
    },
    // 获取角色 列表
    getRoleList() {
      this.total = 0
      this.listLoading = true
      const search = { roleCategory: '0' }
      // search.pageNo=this.search.pageNo
      // search.pageSize=this.search.pageSize
      search.pageNo = 1
      search.pageSize = 999
      const param = new URLSearchParams()
      for (const i in search) {
        param.append(i, search[i] || '')
      }
      this.$http.get(this.$api.roleList, { data: { ...search }}).then(res => {
        this.roleList = res.result && res.result.records || []
      }).catch(() => {
        this.listLoading = false
      })
    },
    // 工时管理 获取标准工时 taskTemplateManhourGet
    taskTemplateManhourGet() {
      this.$http.get(this.$api.taskTemplateManhourGet, { data: '' }).then(res => {
        this.manhourValue = res.result || ''
      })
    },
    // 工时管理 保存标准工时 taskTemplateManhourGet
    taskTemplateManhourEdit() {
      const param = new URLSearchParams()
      param.append('manhourValue', this.manhourValue || '')
      this.btnLoading = true
      this.$http.put(this.$api.taskTemplateManhourEdit, { data: param }).then(res => {
        this.$message.success(res.message)
        this.btnLoading = false
      }).catch(() => {
        this.btnLoading = false
        this.taskTemplateManhourGet()
      })
    },
    // 获取权限
    // folderTemplateGetFolderPermission() {
    //   let param = new URLSearchParams()
    //   param.append('folderId', this.treeActive[0])
    //   this.$http.get(this.$api.folderTemplateGetFolderPermission, { data:param }).then(res => {
    //     // this.appliedToSubfolders=res.result.appliedToSubfolders
    //     let permissions=res.result&&res.result.permissions || []
    //     let _obj={}
    //     permissions.forEach((item)=>{
    //       _obj[item.roleId]={...item}
    //     })
    //     let permissionsObj={}
    //     this.list.forEach((item)=>{
    //       permissionsObj[item.id]={pem:1,roleId:item.id}
    //       if(_obj[item.id]){
    //         permissionsObj[item.id]={..._obj[item.id]}
    //       }
    //     })
    //     this.permissionsObj=permissionsObj
    //   })
    // },
    // 选中左侧树节点
    updateActive(e) {
      this.treeActive = [e.id]
      this.activePathName = e.pathName
      this.information.data = { ...e }
      this.old_information = { ...e }
      this.information.roleIds = []
      if (e.roleIds) {
        this.information.roleIds = e.roleIds.split(',')
      }
      let _parentName = ''
      let _f = false
      const _h = (arr) => {
        arr.forEach(item => {
          if (!_f) {
            if (item.id === e.parentId) {
              _f = true
              _parentName = item.taskName
            } else {
              if (item.children && item.children.length) {
                _h(item.children)
              }
            }
          }
        })
      }
      _h(this.trees)
      this.information.parentName = _parentName
    },
    // 左侧树 点击
    // chooseTree(e) {
    //   this.rightTab='1'
    //   this.search.pageNo=1
    //   if (e && e.length) {
    //     this.folderTemplateGetFolderPermission()
    //     let _information={}
    //     let _f=false
    //     let _h=(arr)=>{
    //       arr.forEach(item=>{
    //         if(!_f){
    //           if(item.id==e[0]){
    //             _f=true
    //             _information=item
    //           }else{
    //             if(item.children&&item.children.length){
    //               _h(item.children)
    //             }
    //           }
    //         }
    //       })
    //     }
    //     _h(this.trees)
    //     this.information.data={..._information}  //选中节点的内容
    //     this.old_information={..._information}
    //   }else{
    //     this.information.data={}
    //     this.old_information={}
    //   }
    // },
    // 确认 删除文件夹
    activeList(url, method, title, params) {
      this.$confirm({ text: '是否确认' + title + '任务？' }).then((vm) => {
        const param = new URLSearchParams()
        for (const i in params) {
          param.append(i, params[i] || '')
        }
        vm.loading = true
        this.$http[method](url, { data: param }).then(res => {
          vm.show = false
          vm.loading = false
          this.$message.success(res.message)
          this.treeActive = []
          this.information.data = {}
          this.old_information = {}
          this.getList()
        }).catch(() => {
          vm.show = false
          vm.loading = false
        })
      }).catch(() => {})
    },
    // 选择上级任务
    chooseParent(e) {
      if (e && e.length) {
        if (this.dialog.show) {
          this.dialog.data.parentId = e[0].id
          this.dialog.parentName = e[0].taskName
          this.dialog.showMenu = false
        } else {
          // information.roleIds
          this.information.data.parentId = e[0].id
          this.information.parentName = e[0].taskName
          this.information.showMenu = false
        }
      } else {
        if (this.dialog.show) {
          this.dialog.data.parentId = ''
          this.dialog.parentName = ''
        } else {
          this.information.data.parentId = ''
          this.information.parentName = ''
        }
      }
    },
    // 确认新增文件夹
    submit(type) {
      let url = this.$api.taskTemplateAdd
      let method = 'post'
      let _data = {}
      if (type === 'edit') { // 编辑
        url = this.$api.taskTemplateEdit
        method = 'put'
        _data = { ...this.information.data, taskCategory: this.tab }
        _data.roleIds = this.information.roleIds.join(',')
      } else {
        _data = { ...this.dialog.data, taskCategory: this.tab }
        _data.roleIds = this.dialog.roleIds.join(',')
      }
      this.btnLoading = true
      this.$http[method](url, { data: { ..._data }}).then(res => {
        this.$message.success(res.message)
        this.dialog.show = false
        this.btnLoading = false
        this.dialog.data = {}
        if (type === 'edit') {
          this.getList('edit')
        } else {
          this.getList()
        }
      }).catch(() => {
        this.btnLoading = false
        if (type === 'edit') this.information.data = { ...this.old_information }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-title {
  width: 150px;
  color: #777;
}
</style>
